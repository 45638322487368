import {
  h,
  render,
} from "https://cdn.skypack.dev/pin/preact@v10.5.15-ayWzdHo1JK6lzASFp2Y9/mode=imports,min/optimized/preact.js";
import htm from "https://cdn.skypack.dev/pin/htm@v3.1.0-Lnrl6ooU0xR8YCDnwwW6/mode=imports,min/optimized/htm.js";
import App from "./App.js";
const html = htm.bind(h);
const buildApp = () => {
  render(html`<${App} />`, document.querySelector(".SWSiteSearchBar"));
};

buildApp();
