import { h } from "https://cdn.skypack.dev/pin/preact@v10.5.15-ayWzdHo1JK6lzASFp2Y9/mode=imports,min/optimized/preact.js";
import { useState } from "https://cdn.skypack.dev/pin/preact@v10.5.15-ayWzdHo1JK6lzASFp2Y9/mode=imports,min/optimized/preact/hooks.js";
import htm from "https://cdn.skypack.dev/pin/htm@v3.1.0-Lnrl6ooU0xR8YCDnwwW6/mode=imports,min/optimized/htm.js";
import "./App.scss";
const html = htm.bind(h);

function App() {
  const [searchTerm, setSearchTerm] = useState();

  const redirectToSearch = (e) => {
    e.preventDefault();
    location = `/searchresults.aspx?s=${encodeURIComponent(searchTerm)}`;
    return false;
  };

  return html`<form
    class="swform SWSearchForm"
    name="SWSearchForm"
    onSubmit=${redirectToSearch}
  >
    <div class="swformfield swformfield--text ">
      <div class="swformfieldwrapper">
        <input
          type="search"
          class="swformfield__control"
          name="sw-search-bar-search-term"
          onkeyup=${(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>
    <div class="swformactions">
      <div class="swformsubmit">
        <button class="swformsubmit__btn" type="submit">Search</button>
      </div>
    </div>
  </form> `;
}

export default App;
